<template>
	<v-app>
		<v-main>
			<v-row class="fill-height" align="start" justify="center">
				<!-- Left Side Image Section -->
				<v-col cols="12" md="6" class="pa-0 d-none d-md-block">
					<v-img
						src="@/assets/img/common/login.jpg"
						alt="Sign up page illustration"
					>
					</v-img>
				</v-col>

				<!-- Right Side Form Section -->
				<v-col cols="12" md="6" class="mt-8 px-6 px-sm-16">
					<div class="mx-0 mx-sm-8 mx-md-n6 mx-lg-16">
						<!-- Header Content -->
						<header>
							<a href="https://www.unosearch.net">
								<v-img
									src="@/assets/img/logo/brand-icon.svg"
									max-height="60"
									max-width="60"
									class="mb-6"
									to="/"
									contain
								></v-img>
							</a>

							<h1>Sign up</h1>
							<p class="grey--text text--darken-1">
								Join us and see your business growing!
							</p>
						</header>

						<!-- Actual Form -->
						<v-form
							v-if="!isSuccess"
							class="mt-10"
							@submit.prevent="signUp"
						>
							<!-- Alert box to display Server Errors -->
							<v-alert
								:value="showServerError"
								type="error"
								dismissible
								transition="fade-transition"
								@click="showServerError = !showServerError"
								class="mb-6"
							>
								{{ serverErrorMessage }}
							</v-alert>

							<!-- FirstName and LastName field -->
							<v-row>
								<v-col class="py-0 pl-0">
									<v-text-field
										v-model="signUpForm.firstName"
										placeholder="First name"
										required
										outlined
										type="text"
										:error-messages="firstNameErrors"
										@blur="$v.signUpForm.firstName.$touch()"
									></v-text-field>
								</v-col>

								<v-col class="py-0 pr-0">
									<v-text-field
										v-model="signUpForm.lastName"
										placeholder="Last name"
										required
										outlined
										type="text"
										:error-messages="lastNameErrors"
										@blur="$v.signUpForm.lastName.$touch()"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Email Field -->
							<v-text-field
								v-model="signUpForm.email"
								placeholder="Email address"
								required
								outlined
								type="email"
								:error-messages="emailErrors"
								@blur="$v.signUpForm.email.$touch()"
							></v-text-field>

							<!-- Password Field -->
							<v-text-field
								v-model="signUpForm.password"
								outlined
								placeholder="Password"
								required
								:append-icon="
									showPassword
										? 'mdi mdi-eye-off'
										: 'mdi mdi-eye'
								"
								@click:append="showPassword = !showPassword"
								:type="showPassword ? 'text' : 'password'"
								:error-messages="passwordErrors"
								@blur="$v.signUpForm.password.$touch()"
							></v-text-field>

							<!-- Terms Agreement Statement -->
							<p class="grey--text text--darken-1 text-caption">
								By clicking on Sign up, you agree to our
								<a
									target="_blank"
									class="link-text"
									href="https://www.unosearch.net/legal/terms-of-service"
									@click.stop
								>
									Terms
								</a>
								,
								<a
									target="_blank"
									class="link-text"
									href="https://www.unosearch.net/legal/privacy-policy"
									@click.stop
								>
									Privacy,
								</a>
								and
								<a
									target="_blank"
									class="link-text"
									href="https://www.unosearch.net/legal/cookie-policy"
									@click.stop
								>
									Cookie policy
								</a>
							</p>

							<!-- Submit Button -->
							<v-btn
								type="submit"
								@click.prevent="signUp"
								:loading="isLoading"
								x-large
								block
								color="primary"
								class="text-capitalize white--text mt-2"
							>
								Sign Up
							</v-btn>

							<!-- Link to Login Page -->
							<p
								class="
									text-center
									mt-8
									grey--text
									text--darken-1
								"
							>
								Already have an account?
								<router-link to="/login" class="link-text">
									Log In
								</router-link>
							</p>
						</v-form>

						<!-- Message to display after Successful Registration -->
						<div
							v-if="isSuccess"
							class="d-flex flex-column align-center mt-10"
						>
							<v-icon size="100" color="success">
								mdi-check-decagram
							</v-icon>

							<h3 class="text-h5 mt-5">Congratulations!</h3>

							<p class="text-subtitle-1 mt-2">
								Your account has been successfully created.
							</p>

							<p class="text-subtitle-2 text-center mt-8 mx-8">
								We've just sent you a verification email. Please
								check your inbox and verify your account. If you
								did not receive any email then try to Login to
								receive again.
							</p>

							<p
								class="
									text-center
									grey--text
									text--darken-1
									mt-8
								"
							>
								Go to
								<router-link to="/login" class="link-text">
									Log In
								</router-link>
							</p>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required,
	maxLength,
	minLength,
	alpha,
	email,
} from 'vuelidate/lib/validators'

export default {
	mixins: [validationMixin],
	validations: {
		signUpForm: {
			firstName: {
				required,
				alpha,
				maxLength: maxLength(100),
			},
			lastName: {
				required,
				alpha,
				maxLength: maxLength(100),
			},
			email: {
				required,
				email,
				maxLength: maxLength(100),
			},
			password: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(100),
			},
		},
	},
	data() {
		return {
			signUpForm: {
				firstName: '',
				lastName: '',
				email: '',
				password: '',
			},
			showPassword: false,
			showServerError: false,
			serverErrorMessage: '',
			isSuccess: false,
			isLoading: false,
		}
	},
	computed: {
		firstNameErrors() {
			const errors = []
			if (!this.$v.signUpForm.firstName.$dirty) return errors
			!this.$v.signUpForm.firstName.required &&
				errors.push('Please enter First name.')
			!this.$v.signUpForm.firstName.alpha &&
				errors.push('First name must contain alphabets only.')
			!this.$v.signUpForm.firstName.maxLength &&
				errors.push('Fist name must be atmost 100 characters long.')
			return errors
		},
		lastNameErrors() {
			const errors = []
			if (!this.$v.signUpForm.lastName.$dirty) return errors
			!this.$v.signUpForm.lastName.required &&
				errors.push('Please enter Last name.')
			!this.$v.signUpForm.lastName.alpha &&
				errors.push('Last name must contain alphabets only.')
			!this.$v.signUpForm.lastName.maxLength &&
				errors.push('Last name must be atmost 100 characters long.')
			return errors
		},
		emailErrors() {
			const errors = []
			if (!this.$v.signUpForm.email.$dirty) return errors
			!this.$v.signUpForm.email.required &&
				errors.push('Please enter a valid Email address.')
			!this.$v.signUpForm.email.email &&
				errors.push('Email must be valid.')
			!this.$v.signUpForm.email.maxLength &&
				errors.push('Email must be atmost 100 characters long.')
			return errors
		},
		passwordErrors() {
			const errors = []
			if (!this.$v.signUpForm.password.$dirty) return errors
			!this.$v.signUpForm.password.required &&
				errors.push('Please enter a secure Password.')
			!this.$v.signUpForm.password.minLength &&
				errors.push('Password must be atleast 8 characters long.')
			!this.$v.signUpForm.password.maxLength &&
				errors.push('Password must be atmost 100 characters long.')
			return errors
		},
	},
	methods: {
		signUp() {
			this.$v.signUpForm.$touch()
			if (!this.$v.signUpForm.$invalid || !this.$v.signUpForm.$error) {
				this.isLoading = true
				this.axios
					.post('/auth/signup', this.signUpForm, { headers: {} })
					.then((res) => res.data)
					.catch((err) => {
						const { data } = err.response
						this.isLoading = false
						this.signUpForm.password = ''
						this.$v.signUpForm.$reset()
						this.serverErrorMessage = !data.success
							? data.message
							: err
						this.showServerError = true
						throw new Error(err)
					})
					.then((res) => {
						if (res.success) {
							this.isLoading = false
							this.isSuccess = true
						}
					})
					.catch((err) => {
						this.isLoading = false
						throw new Error(err)
					})
			}
		},
	},
}
</script>

<style scoped>
.v-image {
	height: 100vh;
}
.v-input {
	font-size: 1.1rem;
}
.link-text {
	text-decoration: none;
	color: --var(primary);
}
</style>
